
(function() {
  var result = { translations: { "lt": { "i18n": { "duration": { "seconds": "{{ seconds }} sek.", "minutes": "{{ minutes }} min.", "hours": "{{ hours }} val.", "hoursAndMinutes": "{{ hours }} val. {{ minutes }} min.", "days": "{{ days }} d.", "shortForm": { "seconds": "{{ seconds }} sek.", "minutes": "{{ minutes }} min.", "hours": "{{ hours }} val.", "days": "{{ days }} d.", "hoursAndMinutes": "{{ hours }} val. {{ minutes }} min." } }, "dateTime": { "quarterAndYear": "{{yearNumber}} m. {{quarterNumber}} ketv." }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{amountNumberValue}} {{currencyCode}}", "phoneNumberWithExtension": "{{phoneNumber}}, pl\u0117t. {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "lt": "static-7.1139"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1139",
    "ar-eg": "static-7.1139",
    "bg": "static-7.1139",
    "bn": "static-7.1139",
    "ca-es": "static-7.1139",
    "cs": "static-7.1139",
    "da": "static-7.1139",
    "de": "static-7.1139",
    "el": "static-7.1139",
    "en-gb": "static-7.1139",
    "en": "static-7.1139",
    "es-mx": "static-7.1139",
    "es": "static-7.1139",
    "et": "static-7.1139",
    "fi": "static-7.1139",
    "fr-ca": "static-7.1139",
    "fr": "static-7.1139",
    "he-il": "static-7.1139",
    "hi": "static-7.1139",
    "hr": "static-7.1139",
    "hu": "static-7.1139",
    "id": "static-7.1139",
    "it": "static-7.1139",
    "ja": "static-7.1139",
    "ko": "static-7.1139",
    "lt": "static-7.1139",
    "ms": "static-7.1139",
    "nl": "static-7.1139",
    "no": "static-7.1139",
    "pl": "static-7.1139",
    "pt-br": "static-7.1139",
    "pt-pt": "static-7.1139",
    "ro": "static-7.1139",
    "ru": "static-7.1139",
    "sk": "static-7.1139",
    "sl": "static-7.1139",
    "sv": "static-7.1139",
    "th": "static-7.1139",
    "tl": "static-7.1139",
    "tr": "static-7.1139",
    "uk": "static-7.1139",
    "vi": "static-7.1139",
    "x-pseudo": "static-7.1139",
    "zh-cn": "static-7.1139",
    "zh-hk": "static-7.1139",
    "zh-tw": "static-7.1139"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:lt', {version: 'static-7.1139'}); }
})();


